import Vue from 'vue'
import VueRouter from 'vue-router'
import axiosIns from '@axios'
import authGuard from '../auth/authGuard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/Products.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/report/:productId',
      name: 'product-report',
      component: () => import('@/views/ProductReport.vue'),
      props: true,
      meta: {
        pageTitle: 'Report',
        breadcrumb: [
          {
            text: 'Products',
            to: { name: 'products' },
          },
          {
            text: 'Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products/report-print/:productId',
      name: 'product-report-print',
      component: () => import('@/views/print/ProductReportPrint.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/portfolios',
      name: 'portfolios',
      component: () => import('@/views/Portfolios.vue'),
      meta: {
        pageTitle: 'Portfolios',
        breadcrumb: [
          {
            text: 'Portfolios',
            active: true,
          },
        ],
      },
    },
    {
      path: '/portfolios/report/:portfolioId',
      name: 'portfolio-report',
      component: () => import('@/views/PortfolioReport.vue'),
      props: true,
      meta: {
        pageTitle: 'Report',
        breadcrumb: [
          {
            text: 'Portfolios',
            to: { name: 'portfolios' },
          },
          {
            text: 'Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/portfolios/report-print/:portfolioId',
      name: 'portfolio-report',
      component: () => import('@/views/print/PortfolioReportPrint.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/universe',
      name: 'universe-portfolio-report',
      component: () => import('@/views/PortfolioReport.vue'),
      props: { portfolioId: 'u' },
      meta: {
        pageTitle: 'Report',
        breadcrumb: [
          {
            text: 'Universe',
            active: true,
          },
          {
            text: 'Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/portfolios/edit/:portfolioId',
      name: 'portfolio-edit',
      component: () => import('@/views/EditPortfolio.vue'),
      props: true,
      meta: {
        pageTitle: 'Edit Portfolio',
        breadcrumb: [
          {
            text: 'Portfolios',
            to: { name: 'portfolios' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/portfolios/report/:portfolioId/:routeKey',
      name: 'virtual-portfolio-report',
      component: () => import('@/views/PortfolioReport.vue'),
      props: true,
      meta: {
        pageTitle: 'Report',
        breadcrumb: [
          {
            text: 'Portfolios',
            to: { name: 'portfolios' },
          },
          {
            text: 'Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/data/underlyings',
      name: 'data-underlyings',
      component: () => import('@/views/Underlyings.vue'),
      meta: {
        pageTitle: 'Underlyings',
        breadcrumb: [
          {
            text: 'Data',
            active: true,
          },
          {
            text: 'Underlyings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/data/issuers',
      name: 'data-issuers',
      component: () => import('@/views/Issuers.vue'),
      meta: {
        pageTitle: 'Issuers',
        breadcrumb: [
          {
            text: 'Data',
            active: true,
          },
          {
            text: 'Issuers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/quick-start',
      name: 'support-quick-start',
      component: () => import('@/views/support/QuickStart.vue'),
      meta: {
        pageTitle: 'Quick Start',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Quick Start',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/full-documentation',
      name: 'support-full-documentation',
      component: () => import('@/views/support/FullDocumentation.vue'),
      meta: {
        pageTitle: 'Full Documentation',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Full Documentation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/knowledge-base',
      name: 'support-knowledge-base',
      component: () => import('@/views/support/KnowledgeBase.vue'),
      meta: {
        pageTitle: 'Knowledge Base',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Knowledge Base',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/terms-and-conditions',
      name: 'support-terms-and-conditions',
      component: () => import('@/views/support/TermsAndConditions.vue'),
      meta: {
        pageTitle: 'Terms & Conditions',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Terms & Conditions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/privacy',
      name: 'support-privacy',
      component: () => import('@/views/support/Privacy.vue'),
      meta: {
        pageTitle: 'Privacy',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Privacy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/about',
      name: 'support-about',
      component: () => import('@/views/support/About.vue'),
      meta: {
        pageTitle: 'About',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'About',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/contact-us',
      name: 'support-contact-us',
      component: () => import('@/views/support/ContactUs.vue'),
      meta: {
        pageTitle: 'Contact Us',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Contact Us',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/help-one',
      name: '/support-help-one',
      component: () => import('@/views/support/Help.vue'),
      meta: {
        pageTitle: 'Help One',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Help One',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/help-two',
      name: '/support/help-two',
      component: () => import('@/views/support/HelpPdfDownload.vue'),
      meta: {
        pageTitle: 'Help Two - PDF',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
          {
            text: 'Help Two - PDF',
            active: true,
          },
        ],
      },
    },
    {
      path: '/upload-spreadsheet',
      name: 'upload-spreadsheet',
      component: () => import('@/views/UploadSpreadsheet.vue'),
      meta: {
        pageTitle: 'Upload Spreadsheet',
        breadcrumb: [
          {
            text: 'Upload Spreadsheet',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/test1',
      name: 'test1',
      component: () => import('@/views/test1.vue'),
      meta: {
        pageTitle: 'Test1a',
        breadcrumb: [
          {
            text: 'test1b',
            active: true,
          },
        ],
      },
    }, {
      path: '/dummy',
      name: 'dummy',
      redirect: { name: 'home' },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Force login for every route
router.beforeEach(authGuard)

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// Intercept 404 status code and redirect them to our 404 page
axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (!error.response || !error.response.status) {
      return Promise.reject(error)
    }
    const { status } = error.response
    if (status === 404) {
      router.push({ name: 'error-404' })
    }
    return Promise.reject(error)
  },
)

export default router
