// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// Chart Colors
// Colors to use in addition to the theme colours on charts that need many colors
// Source: https://sashamaps.net/docs/resources/20-colors/
export const $chartColors = [
  '#9a6324', // brown,
  '#ffe119', // yellow
  '#f032e6', // magenta
  '#bcf60c', // lime
  '#800000', // maroon
]

// APP CONFIG
let app

switch (process.env.VUE_APP_BRAND) {
  case 'srp':
    app = {
      appName: 'StructrPro', // Will update name in navigation menu (Branding)
    }
    break
  case 'se':
    app = {
      appName: 'StructuredEdge', // Will update name in navigation menu (Branding)
    }
    break
  default:
    break
}

app.brand = process.env.VUE_APP_BRAND

export const $themeConfig = {
  app,
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
