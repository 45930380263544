import { $themeConfig } from '@themeConfig'
import { getInstance } from './index'

function checkAppAccess(user) {
  const appBrand = $themeConfig.app.brand

  const access = {
    allowed: false,
    reason: 'permission',
  }

  // eslint-disable-next-line no-param-reassign
  user.subscription = user['https://www.structuredretailproducts.com/subscription']

  if (user.subscription) {
    user.subscription.Services.forEach(element => {
      let hasAccess = false

      if (appBrand === 'srp') {
        hasAccess = element.SrpService === 'StructrPro'
      } else if (appBrand === 'se') {
        hasAccess = element.SrpService === 'StructuredEdge'
      }

      if (hasAccess) {
        // Subscription ends after the indicated date and time in UTC
        const validUntil = new Date(element.ValidUntilUTC) // parse in UTC

        if (Date.now() < validUntil) {
          access.allowed = true
          access.reason = ''
        } else {
          access.reason = 'expiry'
        }
      }
    })
  }

  return access
}

// eslint-disable-next-line consistent-return
const authGuard = (to, from, next) => {
  const authService = getInstance()

  // eslint-disable-next-line consistent-return
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      // Always allow navigating to the "not authorized" page
      if (to.path === '/not-authorized') return next()

      const access = checkAppAccess(authService.user)
      if (!access.allowed) {
        return next(`/not-authorized?reason=${access.reason}`)
      }

      return next()
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  // eslint-disable-next-line consistent-return
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}

export default authGuard
