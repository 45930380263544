import { $themeBreakpoints } from '@themeConfig'
import http from '@axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    portfolios: [],
    portfolioUpdated: false,
    portfolioLoading: false,
    portfolioError: false,
    updatedPortfolios: [],
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_PORTFOLIOS(state, val) {
      state.portfolios = val
    },
    REMOVE_PORTFOLIO_BY_ID(state, id) {
      const index = state.portfolios.findIndex(p => parseInt(p.portfolioId, 10) === id)

      if (index !== -1) {
        state.portfolios.splice(index, 1)
      }
    },
    SET_PORTFOLIO_UPDATED(state, val) {
      state.portfolioUpdated = val
    },
    ADD_UPDATED_PORTFOLIOS(state, portfolioIds) {
      // Convert the IDs to integers
      const portfolioIdsInt = portfolioIds.map(pId => parseInt(pId, 10))

      // Add it to the store
      state.updatedPortfolios.push(...portfolioIdsInt)
    },
    ADD_UPDATED_PORTFOLIO(state, portfolioId) {
      // Check and remove if there's an already existing duplicate
      state.updatedPortfolios = state.updatedPortfolios.filter(p => p !== portfolioId)

      // Add it to the store
      state.updatedPortfolios.push(parseInt(portfolioId, 10))
    },
    REMOVE_UPDATED_PORTFOLIO(state, portfolioId) {
      state.updatedPortfolios = state.updatedPortfolios.filter(p => p !== parseInt(portfolioId, 10))
    },
  },
  actions: {
    updatePortfolios({ state, dispatch }) {
      state.portfolioLoading = true
      http.get('portfolio.cfc?method=getPortfolios')
        .then(response => {
          state.portfolios = response.data.portfolios
        })
        .catch(() => {
          setTimeout(() => {
            dispatch('updatePortfolios')
          }, 3000)
        })
        .then(() => {
          state.portfolioLoading = false
        })
    },
  },
}
